import ReactTextAreaAutosize from 'react-autosize-textarea'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { connect } from 'react-redux'
import Select from 'react-select'
import { createStructuredSelector } from 'reselect'
import styled, { css, keyframes } from 'styled-components'
import { ifNotProp, ifProp, prop, withProp } from 'styled-tools'
import Button from '../../components/Button'
import { CustomInputs } from '../../components/FormFields'
import Input from '../../components/Input'
import Popup from '../../components/Popup/Popup'

import { makeSelectIsMobile } from '../App/selectors'
import { closePinIcon } from './images'
import colorsOptions, {
  applyOpacity,
} from '../../components/stories/fundamentals/colors-styles/colors'
import {
  sizing,
  weights,
} from '../../components/stories/fundamentals/text-styles/text-styles'

const mapStateToProps = createStructuredSelector({
  isMobile: makeSelectIsMobile(),
})

export const PinOptionsWrapper = connect(mapStateToProps)(styled.div`
  position: absolute;
  right: ${ifProp('show', 0, '-25vw')};
  top: 0;
  z-index: 5001;
  max-width: ${ifProp('fullScreen', '100%', '40%')};
  width: ${ifProp('fullScreen', '100%', '25vw')};
  height: 100%;
  background-color: ${ifProp(
    'white',
    colorsOptions.greys.white,
    colorsOptions.greys['grey-10'],
  )};
  border-left: ${ifProp('noLeftBorder', 0, 1)}px solid
    ${colorsOptions.greys['grey-30']};
  transition:
    right 0.3s ease,
    width 0.5s ease;
  border-radius: 16px 0 0 16px;
  box-shadow: -1px 0 6px #00000029;
  ${ifProp(
    'isMobile',
    css`
      width: 100%;
      right: 0;
      max-width: 100%;
      border-left: none;
    `,
  )}
`)

export const Heading = styled.div`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  padding: 18px 16px;
  text-align: center;
  background: ${colorsOptions.greys['grey-10']};

  button {
    ${weights['text-500']};
    ${sizing['text-l']};
    cursor: pointer;
    color: ${colorsOptions.greys['grey-100']};
    &:hover {
      color: ${({ color }) => color || colorsOptions.docuToolsOrange.orange};
    }
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
    &.marginLeft {
      margin-left: 10px;
    }
  }

  .title {
    ${weights['text-700']};
    ${sizing['text-l']};
    vertical-align: top;
    display: inline-block;
    color: ${colorsOptions.greys['grey-100']};
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &.small_title {
      max-width: 40%;
    }

    img {
      margin-left: 5px;
      margin-top: -3px;
    }
  }
`

export const PinAttributesWrapper = styled.div`
  background: ${colorsOptions.greys.white};
  min-height: ${({ height }) => height};
  overflow-x: hidden;
`

export const PartOption = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 17px;
  margin-top: 5px;
  img {
    vertical-align: sub;
  }
`

export const Part = styled.div`
  min-height: 56px;
  ${ifProp('assignee', 'display: flex; min-height: unset')};
  position: relative;
  width: 100%;
  border-bottom: ${({ last, noBorder }) =>
    last || noBorder ? 'none' : `1px solid ${colorsOptions.greys['grey-30']}`};
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
`
export const PinRows = styled.div`
  padding: 0 0 0 16px;
  overflow: hidden;
  background: ${colorsOptions.greys.white};
  ${({ relative }) => (relative ? 'position: relative;' : '')}
  ${ifProp(
    'bottomborder',
    `border-bottom: 1px solid ${colorsOptions.greys['grey-30']}`,
  )}
`
export const AutoCompleteHeading = styled.div`
  border-top: 1px solid ${colorsOptions.greys['grey-30']};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  ${sizing['text-r']};
  padding: 16px 8px 8px 16px;
  text-transform: uppercase;
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-60']};
  width: 100%;
  background: ${colorsOptions.greys['grey-10']};
  display: flex;

  & > button {
    margin-left: auto;
  }
`
export const PartInputDiv = styled.div`
  position: relative;
  background: ${colorsOptions.greys.white};
  &.border {
    border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  }
  ${withProp('marginleft', (marginleft) =>
    typeof marginleft !== 'undefined' ? `margin-left: ${marginleft}px` : '',
  )}
`
export const RecentNotesBlock = styled.div`
  display: inline-block;
  width: 100%;
  padding: 5px 5px 5px 0;
`

export const PinTitleTextArea = styled(ReactTextAreaAutosize)`
  ${sizing['text-xl']};
  ${weights['text-700']};
  width: 100%;
  caret-color: ${colorsOptions.docuToolsOrange.orange};
  padding-bottom: 15px;
  border-bottom: 2px solid ${colorsOptions.docuToolsOrange.orange};

  ::selection {
    background: ${applyOpacity(colorsOptions.docuToolsOrange.orange, 0.6)};
  }
`
export const PartInputTwo = styled.div`
  padding: 32px 24px;
`
export const PartSelectTagTwo = styled.div`
  display: inline-block;
  padding: 10px 0 0 0;
  ${ifProp('fullwidth', 'width: calc(100% - 44px);')};
`
export const FloatingLabel = styled.div`
  display: ${ifProp('visible', 'block', 'none')};
  opacity: ${ifProp('visible', '1', '0')};
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  ${sizing['text-s']};
  ${ifProp(
    'maxLength',
    css`
      max-width: ${prop('maxLength')}px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,
  )}
`
export const Labelmsg = connect(mapStateToProps)(styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  ${ifProp(
    'isMobile',
    css`
      ${sizing['text-s']};
    `,
  )}
`)

export const DynamicFloatingLabel = styled(FloatingLabel)`
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ArrowRightTagTwo = styled.div`
  position: absolute;
  right: 0;
  float: right;
  margin-top: ${ifProp('marginTop', prop('marginTop'), 7)}px;
  display: inline-block;
  top: 5px;
`
export const CustomSelect = styled.div`
  width: 100%;
  min-height: 56px;
  background: ${colorsOptions.greys.white};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  padding: 14px 0 10px 16px;
  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
  `}
  select {
    ${weights['text-600']};
    ${sizing['text-l']};
    border: 1px solid ${colorsOptions.greys['grey-40']};
    padding: 5px 10px;
    width: 48%;
    &:first-child {
      margin-right: 2%;
    }
    option {
      ${weights['text-600']};
      ${sizing['text-l']};
      padding: 5px 10px;
    }
  }
`
export const CategoryName = styled.span`
  ${sizing['text-l']};
  ${weights['text-600']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  color: ${prop('color')};
  width: ${({ width }) => width || '100%'};
  max-width: 220px;
  ${ifProp(
    'isMobile',
    css`
      max-width: 269px;
      width: auto;
    `,
  )}
  span.deactivatedInfo {
    color: ${colorsOptions.greys['grey-50']};
    margin-left: 5px;
    line-height: 26px;
  }
  &.active {
    color: ${({ color }) => color || colorsOptions.docuToolsOrange.orange};
  }
`
export const SelectedOption = styled.span`
  position: absolute;
  top: 13px;
  left: -4px;
`

export const PinOption = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 10px 10px 6px 17px;
  margin-top: 5px;
  cursor: pointer;
  img {
    vertical-align: sub;
  }
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}px;` : '')}
  ${({ color }) =>
    color &&
    `
    &:hover {
      color: ${color};
    }`}
`
export const PinStatusIcon = styled.span`
  margin-top: -20px;
  display: inline-block;
  vertical-align: middle;
`
export const AlertBox = styled.div`
  display: block;
  padding: 12px 16px 12px 16px;
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  position: relative;
  width: 100%;
  min-height: 56px;
  background: ${colorsOptions.greys.white};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
`
export const MessageBox = styled.div`
  display: block;
  padding: 12px 16px 12px 16px;
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  position: relative;
  width: 100%;
  background: ${colorsOptions.greys.white};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
`
export const ButtonBox = styled.div`
  margin-top: 16px;
`
export const TaskButton = styled(Button)`
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;
`
export const TaskButtonLink = styled(Button)`
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;
`
export const ArrowRight = styled.div`
  float: right;
  margin-top: -10px;
  display: inline-block;
`
export const SliderDiv = styled.div`
  &.audiosliderwide {
    width: 84%;
    display: inline-block;
    margin-left: 80px;
    margin-top: 20px;
  }
  .rc-slider-disabled {
    background-color: ${colorsOptions.greys.white};
  }
  .rc-slider {
    .rc-slider-rail {
      background-color: ${colorsOptions.greys['grey-10']};
    }
    .rc-slider-track {
      background-color: ${colorsOptions.greys['grey-60']};
    }
    .rc-slider-handle {
      z-index: 0;
      border-color: ${({ zero }) =>
        zero ? colorsOptions.greys.white : colorsOptions.greys['grey-60']};
      border-width: 3px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.75);

      border-color: ${colorsOptions.greys.white};
    }
  }
  .rc-slider-step {
    .rc-slider-dot {
      border: none !important;
      width: 4px;

      &.rc-slider-dot-active {
        border: none;
        width: 4px;
      }
    }
  }
  .rc-slider-mark-text {
    margin-top: -29px;
    ${sizing['text-xxxl']};
    color: ${colorsOptions.greys['grey-50']};

    &.rc-slider-mark-text-active {
      color: ${colorsOptions.greys['grey-50']};
    }
  }

  &:disabled {
    .rc-slider-handle {
      display: none;
    }
  }
`
export const PinFieldHeadingWrap = styled.div`
  display: flex;
  gap: 8px;
  ${sizing['text-r']};
  padding: 16px 0 8px 15px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > svg {
    flex: 0 0 auto;
  }

  & > span {
    flex: 2 1 auto;
  }

  .label {
    flex: 1 2 auto;
    text-align: end;
    min-width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin-left: auto;
    //padding-bottom: 16px;
  }
`

export const CategoryNameTwo = styled.span`
  ${sizing['text-l']};
  ${weights['text-600']};
  max-width: 251px;
  width: 100%;
  display: inline-block;
  ${ifProp('removed', `color: ${colorsOptions.greys['grey-50']}`)}

  span.deactivatedInfo {
    color: ${colorsOptions.greys['grey-50']};
    margin-left: 5px;
    line-height: 26px;
  }
`
export const BackArrow = styled.button`
  float: left;
  margin-top: -14px;
  margin-left: -16px;
`
export const PartInputFour = styled.div`
  display: block;
  padding: 16px 11px;
  vertical-align: middle;
  width: 100%;
`
export const PinInputsText = styled(CustomInputs)`
  border-bottom: 0;
  ${sizing['text-l']};
  ${weights['text-600']};
  width: ${ifProp('widthPercent', prop('widthPercent', 85))}%;
  overflow: scroll;
  margin: 0;
  height: 24px;
  &::-webkit-input-placeholder {
    color: ${colorsOptions.greys['grey-50']};
  }
  &::-moz-placeholder {
    color: ${colorsOptions.greys['grey-50']};
  }
  &:-ms-input-placeholder {
    color: ${colorsOptions.greys['grey-50']};
  }
  &:-moz-placeholder {
    color: ${colorsOptions.greys['grey-50']};
  }
`
export const PinIsNotOpenWarningPopupOverlay = styled(Popup)`
  position: absolute;
  width: 360px;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition:
    opacity 0.5s,
    bottom 0.5s ease;
  margin-top: -7px;
  height: 100%;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`

export const PinIsNotOpenWarningPopup = styled.div`
  width: 300px;
  padding: 15px;
  border-radius: 10px;
  background: ${colorsOptions.greys.white};
  transform: translate(-50%, 0%);
  left: 50%;
  position: absolute;
  margin-top: 10px;

  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .warningMessage {
    margin-bottom: 5px;
  }
`
export const FilterButton = styled(Button)`
  margin-right: 10px;
  display: inline-block;
  padding: 8px 25px;
  color: ${({ active, color }) =>
    active ? color : colorsOptions.greys['grey-100']};
  &.right_btn {
    float: right;
  }
  &.wide {
    display: block;
    width: 100%;
  }
  &.smallPadding {
    padding: 8px 15px;
  }
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`

export const FilterPart = styled.div`
  position: relative;
  width: 100%;
  min-height: 56px;
  background: ${colorsOptions.greys.white};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  padding: 14px 16px 12px;
  &.nobackground {
    background: none;
  }
  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
    /*&:hover {
    border-bottom: 1px solid ${({ color }) => color || colorsOptions.docuToolsOrange.orange};
    transition: border 0.5s;
    }*/
  `}
`
export const ToggleBtnLabel = styled.span`
  ${sizing['text-l']};
  padding-top: 3px;
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-60']};
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
`
export const SelectedOptionAlert = styled.span`
  position: absolute;
  top: 13px;
  left: -10px;
`
export const TitleNameTwo = styled.span`
  ${sizing['text-l']};
  ${weights['text-600']};
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 210px;
  margin-top: 19px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  color: ${({ color }) => color || colorsOptions.greys['grey-100']};
  span.deactivatedInfo {
    color: ${colorsOptions.greys['grey-50']};
    margin-left: 5px;
    line-height: 26px;
  }
`
export const ArrowRightTag = styled.div`
  position: absolute;
  right: 0;
  float: right;
  margin-top: 7px;
  display: inline-block;
  top: 5px;
`

export const EmptyList = styled.div`
  margin-top: 10px;
  padding: 10px;
  text-align: center;
`
export const ListDate = styled.div`
  margin-top: 34px;
  ${weights['text-600']};
  ${sizing['text-s']};
  text-align: center;
  margin-bottom: 16px;
  color: ${colorsOptions.greys['grey-60']};
`

export const Post = styled.div`
  position: relative;
  opacity: ${({ show }) => (show ? 1 : '.2')};
  transition: opacity 1s;
  width: 100%;
  min-height: 56px;
  background: ${({ inactive }) =>
    inactive ? colorsOptions.greys['grey-10'] : colorsOptions.greys.white};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  border-top: 1px solid ${colorsOptions.greys['grey-30']};
  margin-bottom: 32px;
`

export const PostHeader = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
  img {
    vertical-align: bottom;
  }
`
export const PostInside = styled.div`
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 12px;
`
export const CreatorImg = styled.div`
  float: left;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  img {
    max-width: 100%;
  }
`
export const HeadingText = styled.div`
  display: inline-block;
  ${weights['text-600']};
  ${sizing['text-s']};
  padding: 5px 10px 0 10px;
  color: ${colorsOptions.greys['grey-100']};
`
export const HeadingDate = styled.div`
  color: ${colorsOptions.greys['grey-50']};
  ${weights['text-600']};
  ${sizing['text-s']};
`
export const HeadingSettings = styled.div`
  position: absolute;
  top: -1px;
  right: 16px;
`

export const PostDetail = styled.div`
  padding-top: 7px;
`
export const PostInfoSubTitle = styled.div`
  color: ${colorsOptions.greys['grey-50']};
  ${sizing['text-s']};
  ${weights['text-600']};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`
export const PostInfoAttribute = styled.div`
  margin-bottom: 10px;
  ${weights['text-600']};
  ${sizing['text-l']};
  color: ${colorsOptions.greys['grey-100']};
  overflow: hidden;
  word-wrap: break-word;
  padding-right: 16px;
`
export const PostInfoDescriptionShowAll = styled.button`
  color: ${({ color }) => color};
  margin-left: 4px;
  cursor: pointer;
`
export const VisualsFilesPreview = styled.div`
  position: relative;
  margin-top: 10px;
  width: 100%;
  white-space: nowrap;
`
export const VisualsFilePreview = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  white-space: nowrap;
  max-width: 120px;
  min-width: 120px;
  overflow: hidden;
  margin-bottom: 10px;
  vertical-align: middle;
  object {
    max-width: 100%;
    height: 120px;
  }
  img {
    height: auto;
  }

  .CommentImageRemove {
    display: none;
  }

  &:hover {
    .CommentImageRemove {
      display: block;
    }
  }
  ${ifProp('loading', 'min-height: 130px;')}
`
export const VideoPlayIndicator = styled.div`
  position: absolute;
  top: calc(50% - 23px);
  left: calc(50% - 23px);
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  background: ${applyOpacity(colorsOptions.greys['grey-100'], 0.3)};
  border-radius: 24px;

  img {
    height: 46px !important;
    width: 46px !important;
  }
`
export const PostInfoAttributeSeparator = styled.div`
  border-top: 1px solid ${colorsOptions.greys['grey-20']};
  border-bottom: ${({ bottom }) => (bottom ? 1 : 0)}px solid
    ${colorsOptions.greys['grey-20']};
  height: 10px;
`
export const PostInfoAttributeLabel = styled.span`
  color: ${colorsOptions.greys['grey-50']};
  margin-right: 5px;
  ${sizing['text-s']};
  ${weights['text-600']};
`
export const PostInfoAttributeCommentsButton = styled.button`
  cursor: pointer;
`
export const PostInfoAttributeLabelArrow = styled.span`
  cursor: pointer;
  float: right;
  margin-right: 10px;
  margin-top: -3px;
`
export const CommentsHeading = styled.div`
  text-align: center;
  color: ${colorsOptions.greys['grey-50']};
  margin-bottom: 15px;
  ${sizing['text-r']};
  ${weights['text-600']};
`
export const CommentBody = styled.div`
  padding-left: 50px;
  padding-top: 10px;
`
export const CommentText = styled.div`
  padding-bottom: 8px;
  ${sizing['text-s']};
  word-wrap: break-word;
  ${weights['text-500']};
  color: ${colorsOptions.greys['grey-100']};
`
export const CreateCommentWrapper = styled.div`
  position: absolute;
  bottom: 0;
  background: ${colorsOptions.greys.white};
  border-top: 1px solid ${colorsOptions.greys['grey-30']};
  width: 100%;
`
export const CommentBox = styled.div`
  padding: ${({ height }) => (height ? 0 : 10)}px;
  height: ${({ expanded, height }) => (expanded ? height || 95 : 50)}px;
  transition: height 0.4s;
`
export const CommentTextArea = styled(ReactTextAreaAutosize)`
  ${sizing['text-s']};
  ${weights['text-500']};
  width: calc(100% - 100px);
  margin-left: 5px;
`
export const SendImageWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  width: 40px;
`
export const SendButtonWrapper = styled.div`
  height: 40px;
  width: 40px;

  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }

  button {
    border-radius: 40px;
    cursor: pointer;
    font-size: 0.8em;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }
`

export const CommentPhoto = styled.button`
  border: 1px solid ${colorsOptions.greys['grey-30']};
  overflow: hidden;
  &:hover {
    border-color: ${colorsOptions.greys['grey-50']};
  }
`
export const VisualPreviewDiv = styled.div`
  position: relative;
  margin-bottom: 8px;
  border-radius: 9px;
  overflow: hidden;

  &:nth-child(odd) {
    margin-right: 8px;
  }

  .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  ${withProp('width', (width) => {
    if (typeof width === 'number') {
      return `
        width: ${width}px;
        height: ${width}px;
      `
    }
    return 'width: 100%;'
  })}

  ${withProp('background', (background) => {
    if (background) {
      return `
        background: url("${background}");
        background-size: cover;
      `
    }
    return ''
  })}
`
export const VisualPreviewRemove = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: ${colorsOptions.greys.white};
  border-radius: 100%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`
export const VisualPreviewDraw = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  color: ${colorsOptions.greys.white};
  padding: 0;
  ${sizing['text-l']};
  ${weights['text-500']};
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`
export const VisualPreviewRotate = styled(VisualPreviewDraw)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  top: auto;
  left: auto;
  bottom: 10px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 1);
  ${ifProp('left', 'left: 10px;')}
  ${ifProp('right', 'right: 10px;')}
`
export const AddMoreButton = styled(FilterButton)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  width: calc(100% - 20px);
`
export const DescriptionAudioPlayer = styled.div`
  border-top: 1px solid ${colorsOptions.greys['grey-30']};
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 56px;
  padding: 8px 16px;
  background: ${colorsOptions.greys.white};
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  .audioTime {
    float: left;
    margin-top: 11px;
  }
`
export const SliderDivAudio = styled(SliderDiv)`
  float: left;
  margin-top: 12px;
  margin-left: 8px;
  width: 45%;
  margin-right: 12px;
`
export const FilterButtonAudio = styled(FilterButton)`
  float: left;
  padding: 0;
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  img {
    margin-left: -2px;
  }
`
export const AddMoreButtonPlaceHolder = styled.div`
  height: 40px;
`
export const AudioImg = styled.img`
  margin-left: -14px;
`
export const AudioMiddleSection = styled.div`
  margin-bottom: ${({ noMargingBottom }) => (noMargingBottom ? 0 : '10px')};
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
`
export const BtnBlockTwo = styled.div`
  position: relative;
  width: 15%;
  float: left;
  height: 42px;
`

export const AudioSliderWrapper = styled.div`
  width: 83%;
  position: relative;
  padding: 8px 10px;
  overflow: hidden;
  float: right;
  .audioslider {
    display: inline-block;
    width: 72%;
    margin-right: 8%;
    margin-left: 2%;
  }

  .audiosliderwide {
    width: 80%;
    display: inline-block;
    margin-left: 80px;
    margin-top: 20px;
  }
`
export const AudioBtn = styled(FilterButton)`
  width: 42px;
  height: 42px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  left: 0;
  top: -3px;
  z-index: 0;
`
export const FileAudioBtn = styled(FilterButton)`
  width: 32px;
  height: 32px;
  overflow: hidden;
  padding: 15px;
  margin-top: 15px;
  margin-left: 15px;

  img {
    margin-left: -15px;
    margin-top: -20px;
  }
`
export const DisplayFilesNumber = styled.div`
  display: block;
  padding-left: 20px;
  padding-bottom: 10px;
`
export const Numbers = styled.span`
  display: inline-block;
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-60']};
  margin-right: 18px;
  margin-left: 5px;
`
export const RecentNoteExpand = styled.button`
  color: ${({ color }) => color};
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
export const FileList = styled.ul`
  list-style-type: none;
  li:last-of-type {
    border-bottom: 0;
  }
`
export const File = styled.div`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  position: relative;
  width: 100%;

  padding: ${ifProp('activityList', 0, '5px')} 5px 0 0;
  background: ${colorsOptions.greys.white};

  ${ifProp(
    'activityList',
    css`
      &:last-child {
        border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
      }
    `,
    `border-bottom: 1px solid ${colorsOptions.greys['grey-30']};`,
  )}

  .deactivate {
    display: ${ifNotProp('showdeactivate', 'none', 'inline-block')};
    align-self: center;
  }

  &:hover {
    .deactivate {
      display: inline-block;
    }
  }
`
export const FileInfo = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  min-width: 0;
  ${ifProp('isInactive', 'opacity: 0.5')}
`

export const FileLink = styled.a`
  display: flex;
  text-decoration: none;
  background: ${colorsOptions.greys.white};
  flex-wrap: nowrap;
  flex-grow: 1;
  overflow: hidden;
`

export const SoundSection = styled.div`
  display: flex;
  background: ${colorsOptions.greys.white};

  padding: 0 24px 16px 4px;
  align-items: center;

  .play {
    flex: 0 0 40px;
    margin-right: 16px;
    cursor: pointer;
    border-radius: 30px;
    &:hover {
      box-shadow: inset 1px 1px 12px ${colorsOptions.greys['grey-30']};
    }
    &:disabled {
      box-shadow: none;
    }
  }
  .slider {
    flex: 1 1;
  }
  .duration {
    margin-left: 16px;
    flex: 0 0;
    white-space: nowrap;
    ${sizing['text-s']};
    ${weights['text-500']};
    color: ${colorsOptions.greys['grey-50']};
    letter-spacing: 0.1em;
  }
`

export const FileIcon = styled.div`
  flex: 0 0 48px;
  position: relative;
`
export const FileSuffix = styled.div`
  display: flex;
  justify-content: center;
  color: ${colorsOptions.greys['grey-60']};
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${withProp('length', (length) => (length > 3 ? 8 : 10))}px;
  ${weights['text-600']};
  top: 25px;
  width: 100%;
`
export const FileName = styled.div`
  ${sizing['text-l']};
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const FileSize = styled.div`
  margin-top: 4px;
  text-transform: ${ifProp('isSoundError', 'unset', 'uppercase')};
  color: ${ifProp(
    'isSoundError',
    colorsOptions.secondaryColors.red.strong,
    colorsOptions.greys['grey-50'],
  )};
  white-space: ${ifProp('isSoundError', 'unset', 'nowrap')};
  ${weights['text-500']};
  ${sizing['text-s']};
  text-overflow: ellipsis;
  ${ifProp('isSound', 'letter-spacing: 0.1em;')}
`
export const FileDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  text-decoration: none;
  flex-wrap: nowrap;
  overflow: hidden;
  min-width: 0;
  ${ifProp(
    'activityList',
    css`
      flex: 1;
      border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
      &:last-child {
        border-bottom: none;
      }
    `,
  )}
`
export const FileAudioLength = styled.span`
  float: right;
`
export const TaskTitle = styled.div`
  margin-bottom: 10px;
  ${weights['text-600']};
  color: ${({ color }) => color || colorsOptions.greys['grey-100']};
  ${sizing['text-l']};
  word-wrap: break-word;
  cursor: pointer;
`
export const PostAttributeLabel = styled.div`
  color: ${colorsOptions.greys['grey-50']};
  margin-right: 5px;
  ${sizing['text-s']};
  ${weights['text-600']};
`
export const DueDateMissed = styled.span`
  ${sizing['text-s']};
  margin-left: 5px;
  color: ${({ color }) => color || colorsOptions.secondaryColors.orange.strong};
`
export const TaskEventAndActionWrapper = styled.div`
  border-left: 5px solid ${colorsOptions.greys['grey-30']};
  border-top: 1px solid ${colorsOptions.greys['grey-20']};

  &:last-child {
    border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
  }
`
export const TaskStatus = styled.div`
  ${weights['text-600']};
  color: ${({ color }) => color || colorsOptions.greys['grey-100']};
  vertical-align: middle;
  padding: 10px 0;
  span {
    vertical-align: middle;
  }
`
export const TaskActionButton = styled(Button)`
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px;
`

export const AddOption = styled.div`
  background: ${colorsOptions.greys.white};
  box-shadow: 2px 3px 10px 0 grey;
  text-align: right;
  padding: 0 3px;
  width: 100%;
  border-radius: 30px;
  cursor: pointer;
  ${weights['text-500']};
  ${sizing['text-l']};
  span {
    vertical-align: middle;
    display: inline-block;
    width: 78%;
    text-align: left;
  }
  margin-top: 10px;
`
export const PlusButtonOptions = styled.div`
  padding: 15px 15px ${({ bigger }) => (bigger ? '80px' : 0)} 15px;
`

export const SubmenuWrapper = styled.div`
  position: relative;

  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 40px;
    left: auto;
    right: 0;
    width: ${({ small }) => (small ? 150 : 270)}px;
    border: 1px solid ${colorsOptions.greys['grey-20']};
    box-shadow: 0 1px 5px 0 rgba(20, 26, 33, 0.2);
    z-index: 99;
    @media (max-width: 767px) {
      top: 40px;
      width: 289px;
    }
    &:after {
      position: absolute;
      right: 8px;
      top: -11px;
      width: 0;
      height: 0;
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${colorsOptions.greys['grey-20']};
    }

    &:before {
      content: '';
      position: absolute;
      right: 8px;
      top: -9px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent;
      z-index: 3;
    }
    &:hover {
      &:before {
        border: none;
      }
    }
    li {
      list-style-type: none;
      border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
      text-align: left;
      &:last-child {
        border-bottom: none;
      }
    }
  }
`
export const SubmenuToggleButton = styled.a`
  color: ${colorsOptions.greys['grey-100']};
  border-radius: 40px;
  display: inline-block;
  width: 40px;
  height: 40px;
  float: right;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  letter-spacing: 1px;
`
export const SubmenuEntryButton = styled.a`
  text-decoration: none;
  padding: 7px 12px;
  color: ${({ disabled }) =>
    disabled
      ? colorsOptions.greys['grey-50']
      : colorsOptions.greys['grey-100']};
  ${sizing['text-l']};
  display: block;
  cursor: pointer;
  background: ${colorsOptions.greys.white};
  width: 100%;
  ${weights['text-600']};
  &:hover {
    background: ${colorsOptions.greys['grey-20']};
  }
  @media (max-width: 767px) {
    padding: 15px 12px;
  }
`
export const RecentNoteInfo = styled.div`
  margin-top: -10px;
  ${sizing['text-l']};
  span {
    ${sizing['text-s']};
    ${weights['text-600']};
  }
  .heading {
    ${sizing['text-s']};
    ${weights['text-600']};
    color: ${colorsOptions.greys['grey-60']};
  }

  .category {
    color: ${({ color }) => color};
    ${sizing['text-s']};
    ${weights['text-500']};
  }
`
export const RecordInfo = styled.span`
  color: ${colorsOptions.docuToolsOrange.orange};
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 16px;
  &.next_section {
    margin-top: 9px;
    margin-left: 0;
  }
`
export const BtnBlock = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 20px;
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
`
export const AudioPlaySection = styled.span`
  display: flex;
  align-items: center;

  img {
    flex: 0 0 65px;
    margin-left: -14px;
  }
  .recordingMin {
    ${sizing['text-l']};
    ${weights['text-600']};
    color: ${colorsOptions.greys['grey-100']};
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
    margin-left: -5px;
  }
`
export const AudioBottomSection = styled.div`
  margin-bottom: 20px;
`
export const EntireDatasetWrapper = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      tr {
        cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
        &:hover {
          td {
            color: ${colorsOptions.greys['grey-50']};
          }
        }
      }
    }

    tr {
      background: ${colorsOptions.greys.white};
      &:nth-child(odd) {
        background: ${colorsOptions.greys['grey-20']} !important;
      }

      th {
        color: ${colorsOptions.greys['grey-100']};
        &.active {
          color: ${({ color }) => color};
        }

        background: ${colorsOptions.greys.white};
        border: 1px solid ${colorsOptions.greys['grey-50']};
        ${weights['text-600']};
        ${sizing['text-l']};
        padding: 10px;
      }
      td {
        border: 1px solid ${colorsOptions.greys['grey-50']};
        ${weights['text-600']};
        ${sizing['text-l']};
        padding: 10px;
      }
    }
  }
`

export const RecordWrapper = styled.div`
  white-space: nowrap;
  max-width: 325px;
`

export const LinkedRecordTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`
export const LinkedRecordId = styled.div`
  ${weights['text-500']};
  margin-top: -10px;
  overflow: hidden;
  ${sizing['text-s']};
  text-overflow: ellipsis;
`
export const SaveButton = styled.button`
  position: absolute;
  right: 0;
  top: 13px;
  background: rgb(246, 246, 250, 0.8);
  padding: 5px;
`

export const RepeatSelect = styled(Select)`
  display: inline-block;
  width: 49%;
`

export const AssigneeType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  ${ifProp(
    'isTeamGroup',
    css`
      & > img {
        width: 40px;
        height: 40px;
        margin-left: -0.5em;
      }
    `,
    css`
      padding: 0 10px 0 0;
      & > img {
        width: 20px;
        height: 20px;
      }
    `,
  )}
`

export const AssigneeOption = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  padding: ${ifProp('noPadding', 0, '11px 0')};

  span {
    line-height: initial;
  }
  span:nth-child(2) {
    ${sizing['text-s']};
  }
  & > *:nth-child(2) {
    max-width: none;
  }
`

export const TeamPopOver = styled.div`
  position: absolute;
  width: auto;
  min-width: 80%;
  background: ${colorsOptions.greys.white};
  box-shadow: 0 0 0.1em ${colorsOptions.greys['grey-20']};
  z-index: 999;
  right: 0;
  padding: 0.2em;
  &:after {
    position: absolute;
    right: 8px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 999;

    ${ifProp(
      { direction: 'up' },
      `
    bottom: -11px;
    border-top: 10px solid ${colorsOptions.greys['grey-20']};
  `,
      `
    top: -11px;
    border-bottom: 10px solid ${colorsOptions.greys['grey-20']};
  `,
    )}
  }
  &:before {
    position: absolute;
    right: 8px;
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    z-index: 3;

    ${ifProp(
      { direction: 'up' },
      css`
        bottom: -9px;
        border-width: 10px 10px 0 10px;
        border-color: ${ifProp(
            'header',
            colorsOptions.greys['grey-60'],
            colorsOptions.greys.white,
          )}
          transparent transparent transparent;
      `,
      css`
        top: -9px;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent
          ${ifProp(
            'header',
            colorsOptions.greys['grey-60'],
            colorsOptions.greys.white,
          )}
          transparent;
      `,
    )}
  }
`

export const AssigneeDetail = styled.div`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  z-index: 1;
  cursor: ${ifProp('onClick', 'pointer', 'default')};
  ${ifProp('isRemoved', 'opacity: 0.5')};
`
export const DateInfo = `
    padding: 0 12px;
`
export const DueDateWrapperStyle = `
    width: 99.5%;
`

// noinspection CssOverwrittenProperties
export const PinTag = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${colorsOptions.greys['grey-10']};
  border: 1px solid ${colorsOptions.greys['grey-60']};
  color: ${colorsOptions.greys['grey-60']};
  height: 32px;
  align-items: center;
  ${weights['text-600']};
  ${sizing['text-s']};
  padding: 8px;
  flex: 1 1 40%;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 33%;
  width: calc(${prop('charCount', 10)}ch + 32px);
  min-width: 0;
  max-width: calc(${prop('charCount', 10)}ch + 32px);

  .tagText {
    flex-shrink: 1;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const RemoveTag = styled.div`
  cursor: ${ifNotProp('disabled', 'pointer', 'not-allowed')};
  justify-self: flex-end;
  margin-left: auto;
  margin-right: -8px;
`

export const DeleteFailedWarning = styled.div`
  align-self: center;
  color: ${colorsOptions.secondaryColors.red.normal};
  ${sizing['text-l']};
  padding-left: 16px;
  padding-right: 16px;
  ${weights['text-700']};
`

export const PinNotOpenContent = styled.div`
  padding: 16px;
  ${weights['text-400']};
  ${sizing['text-l']};
`

export const Dataset = styled.div`
  display: flex;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  cursor: pointer;
  align-items: center;
  height: 56px;

  &:last-child {
    border-bottom: none;
  }

  .name {
    ${weights['text-600']};
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .arrow {
    flex: 0 0 40px;
  }
`

export const CustomOptionsLabel = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  ${sizing['text-l']};
  ${weights['text-600']};
`

export const VisualPreviews = styled.div`
  display: flex;
  overflow: hidden;
  margin-left: 8px;
  margin-right: 8px;
  flex-wrap: wrap;
`

export const PinWrapper = styled.article`
  display: flex;
  flex-direction: column;
  background: ${colorsOptions.greys['grey-10']};
  border-top-left-radius: 16px;
  @media (max-width: 767px) {
    overflow: hidden;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    box-shadow: -0.5px -2px 3px ${colorsOptions.greys['grey-30']};
    ${ifProp(
      'top',
      css`
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        box-shadow: none;
      `,
    )}
  }
`

export const PinTitleRow = styled.div`
  display: flex;
  padding: 8px 8px 8px 24px;
  align-items: flex-start;
`

export const PinCloseMobile = styled.div`
  flex: 0 0 40px;
  ${ifNotProp('nomargin', 'margin-top: -16px')};
  cursor: pointer;
  background: url('${closePinIcon}');
  background-position-y: top;

  img {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
`

export const PinClose = styled.div`
  flex: 0 0 40px;
  ${ifNotProp('nomargin', 'margin-top: -16px')};
  cursor: pointer;
  border-radius: 20px;
`

export const DesktopTopRow = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colorsOptions.greys['grey-10']};
  height: 40px;
  align-items: center;
  transition: background-color 0.3s;
  border-top-left-radius: 16px;
  &:hover {
    background-color: ${colorsOptions.greys['grey-10']};
  }

  .subscription {
    flex: 1;
    text-align: right;
  }
  .submenu {
    flex: 0 0 40px;
  }
`

export const PinTitle = styled.button`
  text-align: left;
  ${weights['text-700']};
  ${sizing['text-l']};
  cursor: pointer;
  overflow: hidden;
  .pinMainTitle {
    width: 225px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${ifProp(
    'isDisabled',
    css`
      color: ${colorsOptions.greys['grey-50']};
      cursor: default;
    `,
  )}
  ${ifProp(
    'small',
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
  )}
`

export const PinBasicInfosRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};

  .left {
    display: flex;
    flex: 2;
  }
`

export const PinBasicInfoRight = styled.div`
  display: flex;
  flex-grow: 2;
  flex-shrink: 0;
  margin-left: auto;
  flex-direction: column;
  ${weights['text-500']};
  ${sizing['text-s']};
  align-items: flex-end;

  .date {
    color: ${colorsOptions.greys['grey-60']};
  }
`

const PinRoundInfo = css`
  display: flex;
  height: 32px;
  ${sizing['text-s']};
  ${weights['text-600']};
  background: ${colorsOptions.greys['grey-20']};
  border: 1px solid ${colorsOptions.greys['grey-30']};
  border-radius: 16px;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 8px;
  padding-right: 12px;
  cursor: pointer;
  &:hover {
    background: ${colorsOptions.greys['grey-30']};
  }
`

export const PinCategory = styled.div`
  flex-shrink: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  ${PinRoundInfo}
  ${ifNotProp('clickable', 'pointer-events: none;')}
  .colorBox {
    flex: 0 0 12px;
    margin-left: 12px;
    margin-right: 6px;
    background: ${prop('color')};
    height: 12px;
    width: 12px;
  }
  & > .pinBox {
    flex: 0 0 12px;
    margin-left: 12px;
    margin-right: 6px;
  }
  .name {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    ${sizing['text-r']};
  }
  .icon {
    display: flex;
    flex: 0 0 24px;
    margin-left: 6px;
  }
`
export const PinState = styled.div`
  flex-shrink: 0;
  ${PinRoundInfo}
  ${ifNotProp('clickable', 'pointer-events: none;')}
  ${sizing['text-r']};
  margin-right: 0;
  .icon {
    display: flex;
    flex: 0 0 24px;
    margin-left: 6px;
  }
`
export const PinBasicInfoLabel = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  ${sizing['text-r']};
  width: 80%;
  white-space: nowrap;
`

export const PinAttributeHeading = styled.div`
  top: -6px;
  z-index: 2;
  display: flex;
  height: 40px;
  ${sizing['text-r']};
  margin: 0 8px 0 0;

  .icon {
    flex: 0 0 40px;
  }
  .text {
    padding-top: 16px;
    padding-bottom: 8px;
    padding-right: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    flex-grow: 1;
  }

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${colorsOptions.greys['grey-30']};
    flex: 0 0 33px;
    justify-self: center;
    overflow: hidden;

    &:last-child {
      margin-right: 8px;
      border-right: 1px solid ${colorsOptions.greys['grey-30']};
    }
    ${sizing['text-xxxl']}
    cursor: pointer;
    background: ${colorsOptions.greys['grey-10']};
    ${weights['text-400']};
    color: ${colorsOptions.greys['grey-60']};

    span {
      margin-top: -3px;
    }

    &:hover {
      color: ${colorsOptions.greys['grey-100']};
      background: ${colorsOptions.greys['grey-30']};
    }
  }

  .history {
    overflow: hidden;
    padding: 16px 16px 8px 8px;
    ${weights['text-500']};
    margin-right: 8px;
    ${sizing['text-r']};
    cursor: pointer;

    &:hover {
      color: ${colorsOptions.greys['grey-60']};
    }
  }
`

const animationFromBottom = keyframes`
  0% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0);
  }
`
const animationFromRight = keyframes`
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
`

const BaseOverlayFrom = styled.div`
  position: absolute;
  background: ${prop('background', colorsOptions.greys.white)};
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1001;
`

export const OverlayFromBottom = styled(BaseOverlayFrom)`
  transform: translateY(0);
  animation: 0.3s ${animationFromBottom};
`
export const OverlayFromRight = styled(BaseOverlayFrom)`
  transform: translateX(0);
  animation: 0.3s ${animationFromRight};
`

export const PinOptionHeading = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  height: 56px;
  background: ${colorsOptions.greys['grey-10']};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};

  .close {
    flex: 0 0 48px;
    cursor: pointer;
  }

  .title {
    flex: 1 1;
    ${sizing['text-xl']};
    ${weights['text-700']};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .save {
    flex: 0 0 auto;
    justify-self: flex-end;
    ${sizing['text-l']};
    ${weights['text-500']};
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const RecentTitle = styled.div`
  cursor: pointer;
  height: 44px;
  padding: 12px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${weights['text-600']};
  ${sizing['text-l']};
  border-bottom: 0.5px solid ${colorsOptions.greys['grey-30']};
  &:last-child {
    border-bottom: none;
  }
`

export const PinHeader = styled.header``

export const FeaturedImage = styled.div`
  background-color: ${colorsOptions.greys['grey-10']};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  transition: height 300ms;
  will-change: height;
  ${ifProp(
    'src',
    css`
      background-image: url('${prop('src')}');
      cursor: pointer;
    `,
  )}
`

export const StyledScrollbars = styled(Scrollbars)`
  & > div:nth-child(3) {
    z-index: 3;
  }
  & > div:first-child {
    ${ifNotProp(
      'scrollable',
      css`
        overflow: hidden !important;
      `,
    )}
  }
`

export const TeamInfo = styled.span`
  margin-left: auto;
  align-self: center;
  margin-right: 0.5em;
`

const fieldInputStyle = css`
  min-width: 90px;
  width: ${prop('width', '90px')};
  ${sizing['text-r']};
  height: 32px;
  border: 1px solid
    ${ifProp(
      'empty',
      colorsOptions.greys['grey-30'],
      colorsOptions.greys['grey-100'],
    )};
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 0.5em;
  color: ${ifProp(
    'invalid',
    colorsOptions.secondaryColors.red.strong,
    'initial',
  )};
  &:hover {
    opacity: 0.8;
  }
  &:focus,
  &:active,
  &:hover {
    border: 1.5px solid ${prop('categoryColor', 'inherit')};
  }
  ${ifProp(
    'readOnly',
    css`
      border: none !important;
      pointer-events: none;
      text-align: end;
    `,
  )}
  ${ifProp(
    'empty',
    css`
      color: ${colorsOptions.greys['grey-50']};
    `,
  )}
`

export const DisplayFieldInput = styled.div`
  ${fieldInputStyle};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: auto;
  width: auto;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${ifProp(
    'empty',
    css`
      color: ${colorsOptions.greys['grey-30']};
    `,
  )}
`

export const FieldInput = styled(Input)`
  ${fieldInputStyle};
  text-align: right;
  &:focus,
  &:active {
    text-align: left;
  }
  &::-webkit-input-placeholder {
    color: ${colorsOptions.greys['grey-30']};
  }
  &::-moz-placeholder {
    color: ${colorsOptions.greys['grey-30']};
  }
  &:-ms-input-placeholder {
    color: ${colorsOptions.greys['grey-30']};
  }
  &:-moz-placeholder {
    color: ${colorsOptions.greys['grey-30']};
  }
`

const fieldSeparatorStyle = css`
  position: absolute;
  content: '';
  border-bottom: 0.5px solid ${colorsOptions.greys['grey-30']};
  width: 98%;
  bottom: 0;
  right: 0;
`
export const InputTextArea = styled(ReactTextAreaAutosize)`
  ${fieldInputStyle};
  ${weights['text-500']};
  padding: 8px;
  margin-left: 1.7em;
  margin-bottom: 5px;
  text-align: ${ifProp('empty', 'right', 'left')};
  color: ${ifProp(
    'invalid',
    colorsOptions.secondaryColors.red.strong,
    'initial',
  )};
  ${prop('additionalStyles')}
  &:focus, &:active {
    text-align: left;
  }
`

export const TagsWrapper = styled.div`
  background-color: ${colorsOptions.greys.white};
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 8px;
  margin-left: auto;
  align-items: baseline;
  padding-left: 32px;
  padding-right: 0.5em;
  padding-bottom: 8px;
  &:after {
    ${fieldSeparatorStyle};
  }
`

export const FieldMainWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${colorsOptions.greys.white};
  padding: 8px 8px 8px 15px;
  & > svg {
    flex: 0 0 auto;
  }
  width: 100%;
  gap: 8px;
  & > span {
    flex: 1 1 auto;
  }
  ${ifNotProp(
    'noBorder',
    css`
      &:not(:last-child):after {
        ${fieldSeparatorStyle}
      }
    `,
  )}
  .hide {
    position: absolute;
    opacity: 0;
    z-index: -100;
    white-space: pre;
  }
  .select__tagSuggestion {
    color: ${colorsOptions.greys['grey-100']};
  }
  .Select__control--is-focused,
  .Select__control--is-focused:hover {
    border-color: transparent;
    box-shadow: none;
  }
`
export const TextFieldWrapper = styled.div`
  display: grid;
  align-items: center;
  padding: 8px 0 8px 15px;
  background-color: ${colorsOptions.greys.white};

  ${ifNotProp(
    'noBorder',
    css`
      &:not(:last-child):after {
        position: relative;
        content: '';
        border-bottom: 0.5px solid ${colorsOptions.greys['grey-30']};
        width: 100%;
        bottom: -8px;
        right: 0;
      }
    `,
  )}
  .hide {
    position: absolute;
    opacity: 0;
    z-index: -100;
    white-space: pre;
  }
`

export const FieldLabelWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding-bottom: 10px;
`

export const SliderWrapper = styled.div`
  min-height: 90px;
  height: auto;
  position: relative;
  background: ${colorsOptions.greys.white};
  padding-right: 16px;
  padding-bottom: 8px;
  ${ifProp('clickable', 'cursor: pointer;')}
  ${ifProp('readOnly', 'pointer-events: none;')}

  .label {
    ${sizing['text-r']};
    ${weights['text-600']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${ifProp('inactive', `color: ${colorsOptions.greys['grey-60']}`)}
  }
  .slider {
    width: calc(100% - ${ifProp('clickable', 32, 50)}px);
    margin-left: 50px;
    padding: 15px 0;
  }
  &:after {
    ${fieldSeparatorStyle};
  }
`

export const DatasetGrid = styled.div`
  display: grid;
  grid-template-columns: 24px auto auto;
  align-items: center;
  padding-left: 8px;
`
export const DatasetLineSeparator = styled.hr`
  grid-column: ${ifProp('isLast', '1/-1', '2/-1')};
  border: none;
  height: 1px;
  background-color: ${colorsOptions.greys['grey-20']};
  margin-top: 8px;
  ${ifNotProp('isLast', 'margin-bottom: 16px;')}
  ${ifProp('isLast', 'margin-left: 8px;')}
`
export const AddTask = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 56px;
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  cursor: pointer;
  padding-left: 7px;
  background-color: ${colorsOptions.greys.white};
  & > svg {
    flex: 0 0 auto;
  }
  width: 100%;
  gap: 8px;
  & > span {
    flex: 1 1 auto;
  }
  &:after {
    ${fieldSeparatorStyle}
  }
  .arrow {
    flex: 0 0 48px;
    margin-left: auto;
    color: ${colorsOptions.greys['grey-100']};
  }
`

export const FieldLabel = styled.span`
  display: inline-block;
  ${sizing['text-r']};
  ${weights['text-600']};
  width: ${prop('width', '215px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${ifProp(
    'inactive',
    colorsOptions.greys['grey-60'],
    colorsOptions.greys['grey-100'],
  )};
`

export const TimeInputWrapper = styled.div`
  ${fieldInputStyle};
  color: ${ifProp('empty', 'initial', colorsOptions.greys['grey-30'])};
  text-align: ${ifProp('focus', 'left', 'right')};
  ${withProp(['empty', 'focus'], (empty, focus) => {
    if (focus) {
      return css`
        border: 1px solid ${prop('categoryColor')};
      `
    }
    if (empty) {
      return css`
        border: 1px solid ${colorsOptions.greys['grey-30']};
      `
    }

    return css`
      border: 1px solid ${colorsOptions.greys['grey-100']};
    `
  })}
`
export const AutoCompleteWrapper = styled.div`
  z-index: 99999999;
  position: relative;
`
export const TagSuggestionDropDown = styled.div`
  background: ${colorsOptions.greys.white};
  width: 100%;
  z-index: 10;
  min-width: 200px;
  position: absolute;
  right: 8px;
  top: 20px;
  ul {
    border: 1px solid ${colorsOptions.greys['grey-30']};
    border-radius: 8px;
    box-shadow: 0 3px 6px #00000029;
  }
`
export const ListItem = styled.div`
  ${sizing['text-s']};
  padding: 0.75em;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  color: ${colorsOptions.greys['grey-100']};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  pointer-events: ${ifProp('disabled', 'none', 'inherit')};
  background: ${colorsOptions.greys.white};
  &:hover {
    background: ${colorsOptions.greys['grey-20']};
  }
  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`
export const Highlighted = styled.span`
  ${weights['text-700']};
  color: ${colorsOptions.greys['grey-100']};
`
export const CharacterCount = styled.div`
  text-align: right;
  background-color: ${colorsOptions.greys.white};
  ${sizing['text-s']};
  padding-right: 10px;
`
