import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  makeSelectLoggedIn,
  makeSelectUserDataEncryptionKeyAvailable,
} from './selectors'
import { Loader, LoaderWrapper } from '../../components/PageLoader'
import Login from './Login'

const ForceLogin = ({ children }) => {
  const loggedIn = useSelector(makeSelectLoggedIn())
  const userDataEncryptionKeyAvailable = useSelector(
    makeSelectUserDataEncryptionKeyAvailable(),
  )

  const dtToken = localStorage.getItem('dtToken')
  if (!loggedIn && !!dtToken) {
    // not logged in, but dtToken is available
    return (
      <LoaderWrapper>
        <Loader className="loader" />
      </LoaderWrapper>
    )
  }
  if (loggedIn && userDataEncryptionKeyAvailable) {
    return <>{children}</>
  }
  return <Login />
}

ForceLogin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ForceLogin
