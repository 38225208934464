import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  makeSelectLoggedIn,
  makeSelectUserDataEncryptionKeyAvailable,
} from './selectors'
import Logout from './Logout'

const ForceLogout = ({ children }) => {
  const loggedIn = useSelector(makeSelectLoggedIn())
  const userDataEncryptionKeyAvailable = useSelector(
    makeSelectUserDataEncryptionKeyAvailable(),
  )

  if (loggedIn || userDataEncryptionKeyAvailable) {
    return <Logout />
  }
  return <>{children}</>
}

ForceLogout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ForceLogout
