import get from 'lodash/get'
import sentryIgnore from '../../sentryignore.json'
import history from './history'
import config from '../../proxyConfig/index'
const SENTRY_DSN = 'https://537fcde41d2841e69a89873b6afdd398@sentry.io/1408197'

// see https://docs.sentry.io/platforms/javascript/#decluttering-sentry
const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'Network Error',
  "Cannot read property '_leaflet_pos' of undefined",
  "Unable to get property '_leaflet_pos' of undefined or null reference",
  'Non-Error exception captured',
  'Non-Error exception captured with keys: message',
  "Unable to get property '_leaflet_pos' of undefined or null reference",
  "undefined is not an object (evaluating 't._leaflet_pos')",
  'SecurityError: SecurityError',
  'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
  'Loading chunk',
  'User is not a member of project. Project Id:',
  "Requires privileges: [User can't access project]"
]

const blacklistUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
]
export default (Sentry) => {
  const rollbar = {}
  const { VITE_REACT_APP_VERSION, VITE_TARGET_ENV } = import.meta.env

  function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  if (Sentry) {
    const release = `${VITE_REACT_APP_VERSION || '00'}${
      !!VITE_TARGET_ENV && VITE_TARGET_ENV !== 'production'
        ? VITE_TARGET_ENV.substring(0, 2)
        : ''
    }`

    const baseUrlRegexPattern = `^${escapeRegExp(config.url)}`

    const baseUrlRegex = new RegExp(baseUrlRegexPattern)

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: VITE_TARGET_ENV,
      debug: VITE_TARGET_ENV !== 'production',
      release,
      ignoreErrors,
      blacklistUrls,
      attachStacktrace: true,
      //  added the sample rate for replays that begin recording immediately  - session replay
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.02,
      // the sample rate for replays that are recorded when an error happens - session replay
      // This sets the sample rate to be 100% as recommended.
      // https://docs.sentry.io/platforms/javascript/guides/capacitor/session-replay/configuration/?original_referrer=https%3A%2F%2Fblog.sentry.io%2F
      replaysOnErrorSampleRate: 0.5,
      tracesSampleRate: 0.01,
      tracePropagationTargets: ['localhost', baseUrlRegex],
      integrations: [
        // The default masking behavior is to replace each character with a *.So all texts
        // in the project are masked by default in sentry() to secure privacy.
        // the default blocking media(img, svg, video, object, picture, embed, map, audio) behavior replaces
        // the element with a placeholder that has the same dimensions.
        // So removed all text masking and all media blocking and only kept all Input mask.
        // (added "MaskAllInput" option, just only to identify the difference between "maskAllText" and ""MaskAllInput"").
        // https://docs.sentry.io/platforms/javascript/guides/capacitor/session-replay/privacy/?original_referrer=https%3A%2F%2Fblog.sentry.io%2F
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: true,
          networkDetailAllowUrls: ['https://traefik.cloud.docu.solutions/'],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header']
        }),
        Sentry.browserTracingIntegration({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      beforeSend (event, hint) {
        const isNonErrorException =
          ignoreErrors.includes(event.exception.values[0].value) ||
          ignoreErrors.includes(hint.originalException?.message)
        if (isNonErrorException) return null
        return event
      }
    })

    // errorInfoText is not needed because the source maps show the reference where the error occurs
    window.Rollbarerror = (errorInfoText, error) => {
      const ignoreError = sentryIgnore.find(({ key, value }) => {
        const errorValue = get(error, key)
        if (typeof errorValue === 'string') {
          return errorValue.startsWith(value)
        }
        if (typeof errorValue === 'number') {
          return errorValue === value
        }
        return false
      })

      if (error && !ignoreError) {
        Sentry.captureException(error)
      }
    }

    window.Rollbar = rollbar
    window.Sentry = Sentry

    return rollbar
  }
}
